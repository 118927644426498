import { graphql } from 'gatsby';
import * as React from 'react';
import NewsDetails from 'components/pages/News/NewsDetails';
import Pages from 'components/Pages';

const NewsDetailsTemplate = ({ data }) => {
  return (
    <Pages>
      <NewsDetails data={data} />
    </Pages>
  );
};

export default NewsDetailsTemplate;

export const query = graphql`
  query getNewsDetail($slug: String , $language: String) {
    newsJson(
      slug: {eq: $slug}
      type: {eq: $language}
    ) {
      content
      date
      featuredImage
      headline
      title
    }
  }
`;
