import React from 'react';
import LEFT_ARROW_ICON from 'images/left-arrow.png';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CustomContainer from 'components/CustomContainer';
import './styles.css';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
    [INLINES.HYPERLINK]: (node, children) => (
      <a className="text-blue-500 underline cursor-pointer">{children}</a>
    ),
  },
};

const NewsDetails = ({ data }) => {
  return (
    <div className="bg-black text-white font-light min-h-screen pt-[70px] news-detail">
      <CustomContainer className="py-14 sm:py-16">
        <Link to="/news">
          <div className="flex items-center cursor-pointer hover:opacity-50 duration-500 mt-2 md:mt-5 lg:mt-0">
            <img src={LEFT_ARROW_ICON} alt="back-arrow" className="w-5 mr-2" />
            <p className="">Return to News Page</p>
          </div>
        </Link>
        <div className="w-full">
          {/* news header */}
          <div className="mt-6 mb-10 xl:mb-[4rem] w-[100%] md:w-[80%]">
            <h3>{data?.newsJson?.title}</h3>
          </div>
          {/* news content */}
          <div className="w-full">
            <img
              alt={data?.newsJson?.title}
              src={data?.newsJson?.featuredImage}
              title={data?.newsJson?.title}
              className="w-full h-[280px] sm:h-[480px] xl:h-[620px] object-cover"
            />
          </div>
          <article className="mt-14 leading-relaxed w-full content" dangerouslySetInnerHTML={{ __html: data?.newsJson?.content }}>
            {/* {documentToReactComponents(
              JSON.parse(data.newsJson.content),
              options,
            )} */}
          </article>
        </div>
      </CustomContainer>
    </div>
  );
};

NewsDetails.propTypes = {
  data: PropTypes.any,
};

export default NewsDetails;
